import React, { useState, useEffect, Suspense, useRef, useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import ProductCard from './Products';
import BarCode from './BarCode';
import Categories from './Categories';
import Types from './Types';
import { Variants as VariantPicker, Addons as AddonPicker, Events as EventPicker, Recipient as RecipientPicker, Price as PricePicker } from './Pickers';

import Products from '../../../api/Products';
import { stringToUUID } from '../../../api/Common';
import { updateCart, addToCart, saveLogging } from '../../../utils/thunks'

import * as actions from '../../../store/actions';

import "./Items.scss";


const REGISTERS_TO_SHOW_BARCODE = []; // all are hidden right now

export const Items = (props) => {
    
    //const ref=useRef();
    const dispatch = useDispatch();

    const mountedRef=useRef(false);

    const [products, setProducts] = useState([]);
    const [initialProducts, setInitialProducts] = useState([]);
    const [productVariants, setProductVariants] = useState([]);
    const [productAddOns, setProductAddOns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [lastCreatedItemId, setLastCreatedItemId] = useState();    
    
    const { register_id: active_register, name, url: shop_url, checkout_url} = props;
    
    const activeProduct = useSelector(state => state.pos[props.register_id].item);
    const showEventRegister = useSelector(state => state.pos[props.register_id].show_event_register);
    const active_category = useSelector(state => state.pos[props.register_id].category);
    const active_type = useSelector(state => state.pos[props.register_id].type);
    const order = useSelector(state => state.pos[props.register_id].order);
    const order_imported = useSelector(state => state.pos[props.register_id].order_imported);
    const order_status = useSelector(state => state.pos[props.register_id].order_status);
    const trigger_picker = useSelector(state => state.pos[props.register_id].trigger_picker);
    const cart = useSelector(state => state.pos[props.register_id].items);
    const new_items = useSelector(state => state.pos[props.register_id].new_items);
    const company=useSelector(state=>state.company);

    //const GUEST_USER_ID = 7;
    const GUEST_USER_ID = company?.config?.guest_user_id || null;

    const [priceOverrideModalShow, setPriceOverrideModalShow]=useState(false);
    const priceOverrideModalCloseHandler = () => {
        setPriceOverrideModalShow(false);
    }

    const [giftCardModalShow, setGiftCardModalShow]=useState(false);
    const giftCardModalCloseHandler = () => {
        dispatch(updateCart(props.register_id));
        dispatch(actions.activeItem([], props.register_id))
        setGiftCardModalShow(false);
    }

    const [eventModalShow, setEventModalShow]=useState(false);
    const eventModalCloseHandler = () => {
        dispatch(updateCart(props.register_id));
        dispatch(actions.activeItem([], props.register_id))
        setEventModalShow(false);
    }

    const [variantModalShow, setVariantModalShow]=useState(false);
    const variantModalCloseHandler = () => {
        setVariantModalShow(false);
    }

    const [addonModalShow, setAddonModalShow]=useState(false);
    const addonModalCloseHandler = () => {
        //console.log("dispatch updateCart Items 60");
        dispatch(saveLogging(`dispatch updateCart Items 60`));
        dispatch(updateCart(props.register_id));
        dispatch(actions.activeItem([], props.register_id))
        setAddonModalShow(false);
    }

    const [itemDetailsModalShow, setItemDetailsModalShow] = useState(false);
    const itemDetailsModalCloseHandler = () => {
        setItemDetailsModalShow(false);
    }

    const addItemHandler = useCallback(async (item, type = 0) =>{

        if (item.id && +order_status===1 && order_imported===false) {
            let hash; // makes a lil family of items

            let localId = localStorage.getItem(`POS-${active_register}-order-id`)
            
            // let a=[...prev.filter(a=>+a.type!==+type)];
            // a.push({id:item.id,name:item.name,type:type,is_taxable:item.is_taxable,hash:hash, product_id:item.product_id, variant_id: item.variant_id, addons: item.addons});
            
            let newActiveItem = {
                id: item.id,
                name: item.name,
                type: type,
                is_taxable: item.is_taxable,
                hash: hash,
                product_id: item.id,
                variant_id: item.variant_id,
                price_override: item.price,
                addons: item.addons,
                events: item.events || null, // events attached to the product
                event: item.event || null, // selected event and user to add to the order
                giftcard: item.giftcard || null, // gift card recipient
            };

            let product = {...item};
            let variant_id, variant_name, variant_price;
            let variants = item?.product_variants?.filter(variant => variant?.product_status_id === 1);
            let parent_id = item.id || null;

            newActiveItem.product_variants = variants || [];

            switch(type){
                case 0: // product
                    hash=stringToUUID(Math.random());

                    // resets variants and addons when a product is selected
                    setProductVariants([]); 
                    setProductAddOns([]);

                    // if the product has one or less variants, load the addons for the default variant
                    if (variants?.length > 0) {
                        variant_id = variants[0].id;
                        variant_name = variants[0].name;
                        variant_price = variants[0].price;

                        newActiveItem = {
                            ...newActiveItem,
                            variant_id: variant_id,
                            variant_name: variant_name,
                            price_override: variant_price,
                            product_variants: variants || [],
                            events: item.events || null,
                        };

                        dispatch(actions.activeItem(newActiveItem, props.register_id));

                        // get the addons for the variant grouped by category
                        _loadAddons(variant_id);
                    }
                    break;
                case 1: // is a variant
                    hash=stringToUUID(Math.random());

                    // get the selected parent product info
                    newActiveItem.parent_id = product.id;

                    // variant info
                    variant_id = item.id;
                    variant_name = item.name
                    variant_price = item.price;

                    // get the addons for the variant grouped by category
                    _loadAddons(variant_id, product);

                    if (product.events?.length>0){
                        parent_id = activeProduct?.id;
                        variant_id = item.id;
                        variant_name = item.name;
                    }

                    newActiveItem = {
                        ...activeProduct,
                        variant_id: variant_id,
                        variant_name: variant_name,
                        price_override: variant_price,
                    };

                    dispatch(actions.activeItem(newActiveItem, props.register_id));
    
                    setVariantModalShow(false);

                    break;
                case 2: // is an addon
                    // add it as an addon to the active item
                    parent_id = activeProduct?.id;
                    variant_id = item.id;
                    variant_name = item.name;
                    variant_price = item.price;
                    break;
                case 3: // is an event
                    variant_id = item.id;
                    variant_name = item.name;
                    variant_price = item.price;
                    setEventModalShow(false);
                    break;
                case 4: // is a gift card
                    variant_id = item.id;
                    variant_name = item.name;
                    variant_price = item.price;
                    setGiftCardModalShow(false);
                    break;
                case 5: // is a price override
                    variant_id = item.id;
                    variant_name = item.name;
                    variant_price = item.price;
                    setPriceOverrideModalShow(false);
                    break;
                default:
                    break;
            }

            if (!variants || variants.length <= 1){ // no variants
                if (product?.events?.length>0 && type!==3) {
                    _loadEvents(variant_id);
                    return;
                }

                if (product?.product_type_id===12 && type!==4) {
                    _loadRecipient(variant_id);
                    return;
                }

                if (variants?.[0]?.allow_price_change && type!==5) {
                    _loadPriceOverride(variant_id);
                    return;
                }

                // if no order is present, create one and grab the number; this number is used to reload the order from localstorage

                // don't need all the info, this is just to send it to Order.update
                const new_item = {
                    hash,
                    parent_id,
                    type,
                    id: item.id ? item.id : product.id,
                    variant_id: variant_id || null,
                    price_override: variant_price || null,
                    qty: 1,
                };

                if (item.event) {
                    new_item.event = item.event;
                }
                if (item.giftcard) {
                    new_item.giftcard = item.giftcard;
                }

                //adding subscription information so that it can be displayed for staff
                if(item.hasOwnProperty('bill_interval') && item.hasOwnProperty('interval_quantity')){
                    new_item.bill_interval = item.bill_interval;
                    new_item.interval_quantity = item.interval_quantity;
                }

                if (type!==3 && item?.events?.length>0) {
                    // if the product is attached to an avent, update the variant and show the attendees list
                    dispatch(actions.activeItem(newActiveItem, props.register_id));
                    _loadEvents(variant_id);
                } else {
                    // send new item list to update order in db
                    // REMOVE LATER: Extra logging to help debug order saving issues
                    dispatch(saveLogging(`dispatch addToCart Items 185`));
                    dispatch(addToCart([ new_item ], false, props.register_id));
                } 
            } else {    // has variants
                // if ( Object.keys(productAddOns).length>0){
                //     setAddonModalShow(true);
                // }else{
                //     setProductVariants(variants);
                //     setVariantModalShow(true);
                // }

                setProductVariants(variants);
                setVariantModalShow(true);
            }
            
        }
        
        if (Object.keys(productAddOns).length>0 && type<=1 && addonModalShow){
            setAddonModalShow(true);
        }
    },[productAddOns, order_status, order_imported, activeProduct, active_register, dispatch, props.register_id, addonModalShow]);


    //mounted check for first load/unload
    useEffect(()=>{
        mountedRef.current = true
        if(mountedRef.current) dispatch(actions.activeItem([], props.register_id));

        return(()=>{
            mountedRef.current = false;
        })
    },[dispatch, props.register_id]);

    const _loadProducts = async (categoryId, typeId) => {
        if (typeId && !Array.isArray(typeId)) typeId = [typeId];
        const res=await Products.get({ category_id: categoryId || null, product_types: typeId || null, product_status_id: 1, include_child_categories: 1 });
        if (res.data){
            return res.data.products;
        }
        return [];
    }

    const _loadAddons=async (id)=>{
        // get the addons for the variant grouped by category
        let addonCategories=[];
        const addons=await Products.Variants.Addons.get({id:id});
        if (addons.data){
            addons.data.forEach(addon=>{
                addonCategories[addon.category_name]={products:[...addon.add_ons]};
            });
        }
        if (Object.keys(addonCategories).length) {  // have to use Object.keys instead of categories.length don't know why
            setProductAddOns(addonCategories);
            setAddonModalShow(true);
        }
    }

    const _loadEvents=async (id)=>{
        setEventModalShow(true);
    }

    const _loadRecipient=async (id)=>{
        setGiftCardModalShow(true);
    }

    const _loadPriceOverride=async (id)=>{
        setPriceOverrideModalShow(true);
    }


    const updateActiveProductLatest = useCallback((last_id) => {
        // if the activeItem doesn't have a proper order_items_id listed then update it
        if (last_id && +activeProduct?.id === +activeProduct?.product_id) {
            dispatch(actions.activeItem({...activeProduct, id: last_id, order_item_id: last_id }, props.register_id));
            setLastCreatedItemId(null);
        }
        setLastCreatedItemId(null);
    },[activeProduct, props, dispatch]);

    useEffect(() => {
        if (lastCreatedItemId) {    // only trigger an update if there is a new last created id
            updateActiveProductLatest(lastCreatedItemId);
        }
    },[lastCreatedItemId, updateActiveProductLatest]);

    // check state for a new item. If it exists, call addItemHandler
    useEffect(() => {
        if (new_items.length>0) {
            // in the future we will make this loop through an array, but for right now just add one item from a single click
            addItemHandler(new_items[0]);
            dispatch(actions.setNewItems([], props.register_id));
        }
    }, [new_items, dispatch, props.register_id, addItemHandler]);

    const examineItemHandler = async (item, type=0) => {
        item.temp_pos_type = type
        setSelectedItem(item);
        setItemDetailsModalShow(true);
    }


    const showBarCode = useCallback(() => {
        let list = [...REGISTERS_TO_SHOW_BARCODE];
        return list.includes(parseInt(props.register_id));
    },[props.register_id]);

    // if a modal was triggered from another window, load items and show the modal
    useEffect(()=>{
        if (trigger_picker===2 && activeProduct?.variant_id){
            _loadAddons(activeProduct.variant_id);
            dispatch(actions.triggerPicker(null, props.register_id)); // resets the trigger
        }
    },[trigger_picker, activeProduct, cart, dispatch, props.register_id]);


    useEffect(() => {
        if (Array.isArray(props.category_id)){
            const _initialProducts = [];
            props.category_id.forEach(cat => {
                Products.Categories.get({id:cat}).then(response => {
                    if (response.data){
                        _loadProducts(cat).then(response => {
                            if (response) {
                                setProducts(response);
                                _initialProducts.push(...response);
                            }
                        }).catch(e => console.error(e));
                    }
                }).catch(e => console.error(e));
            });
            setInitialProducts(_initialProducts);
        }

	}, [props.category_id]);

    useEffect(() => {
        if (Array.isArray(props.type_id)){
            const _initialProducts = [];
            Products.Types.get({id: props.type_id}).then(response => {
                if (response.data){
                    _loadProducts(null, props.type_id).then(response => {
                        if (response) {
                            setProducts(response);
                            _initialProducts.push(...response);
                        }
                    }).catch(e => console.error(e));
                }
            }).catch(e => console.error(e));
            setInitialProducts(_initialProducts);
        }
	}, [props.type_id]);

	useEffect(() => {
        const fetchProducts=async(category_id, type_id)=> {
            if (category_id === -1) category_id = null;
            if (type_id === -1) type_id = null;
            const response = await _loadProducts(category_id, type_id);
            return response;
        }

        if (active_category || active_type) {
            fetchProducts(active_category, active_type).then(res => setProducts(res)).catch(e => console.error(e));
        } else setProducts(initialProducts);

	}, [active_category, active_type, initialProducts]);

    useEffect(() => {
        return () => {
            setProducts([]);
            setInitialProducts([]);
        };
    },[]);

    if (!showEventRegister /*1===1*/) {
        if (!products){
            return (
                <Suspense fallback={
                    <SkeletonTheme color="#e0e0e0">
                        <Skeleton height={30} style={{marginBottom:"1rem"}} />
                        <Skeleton height={12} count={5} />
                    </SkeletonTheme>
                }>
                </Suspense>
            );        
        } else if (props?.is_disabled) {
            return (
                <div className="register-disabled-message">
                    {props?.disabled_message || "We are not accepting orders at this time"}
                </div>
            )
        }
        else {
            return (
                <section className="item-container">
                    <div className="site-col">
                            <Types {...props} />
                            <Categories {...props} />
                            {showBarCode() &&
                                <React.Fragment>
                                    <BarCode click={addItemHandler} />
                                </React.Fragment>
                            }
                            {/*
                            <InputGroup className="mb-3">
                                <FormControl
                                    ref={ref}
                                    placeholder="Enter a product name or description..."
                                    aria-label="Search"
                                    aria-describedby="product-search"
                                    onChange={searchClick}
                                />
                                <Button variant="light" onClick={searchClick} size="sm" id="product-search"><i className="far fa-search" />Search</Button>
                            </InputGroup>
                            /**/}
                        <div className="pos-products">
                            {products?.map((item,i) => {
                                if (+item.id === 258) console.log(item)
                                if (item.product_variants?.length > 0 && +item.product_status_id===1) {
                                    let price = item.product_variants?.[0]?.price;
                                    if (item.product_variants?.length>1 && !props.patron_layout) {
                                        price = item.product_variants.map(variant => +variant.activation_fee || +variant.price);
                                    }
                                    let pitem=[
                                        <ProductCard
                                            key={`product-${item.id}-${i}`}
                                            type={0}
                                            item={item}
                                            click={props.patron_layout ? examineItemHandler : addItemHandler}
                                            image={item?.image}
                                            name={item?.name}
                                            description={item?.description}
                                            price={price}
                                            active={(+activeProduct?.product_id===+item?.id ||+activeProduct?.id===+item?.id) && +activeProduct?.type===0}
                                            loading={loading}
                                            services={props?.type==="services"}
                                        />
                                    ];                                    
                                    return pitem;
                                } else return null;
                            })}
                        </div>

                        <Modal className="" show={itemDetailsModalShow} onHide={itemDetailsModalCloseHandler} size={"lg"} centered data-cy="item-details-modal">
                            <Modal.Header closeButton>
                                <Modal.Title data-cy="detail-product-name">{selectedItem?.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p dangerouslySetInnerHTML={{__html:(selectedItem?.description || "").replace(/(?:\r\n|\r|\n)/g, '<br>')}} data-cy="detail-product-description" />
                                {selectedItem?.product_variants && <p data-cy="detail-product-price">
                                    Price: <span className="bold">${selectedItem?.product_variants[0]?.price}</span>
                                </p>}
                                <p>
                                    {selectedItem?.product_type_id === 1 && selectedItem?.product_variants?.length>0 && //subscriptions
                                        <>
                                            {selectedItem?.product_variants[0].bill_interval==="m" &&
                                                `This subscription will be billed every ${selectedItem.product_variants[0].interval_quantity > 1 ? selectedItem.product_variants[0].interval_quantity : ""} month${selectedItem?.product_variants[0].interval_quantity > 1 ? "s" : ""}.
                                                ${selectedItem?.product_variants[0].bill_num_times ? `This subscription will be billed ${selectedItem?.product_variants[0].bill_num_times} time${selectedItem?.product_variants[0].bill_num_times > 1 ? "s" : ""} total.` : "This subscription will continue until cancelled."}`
                                            }
                                            {selectedItem?.product_variants[0].bill_interval==="y" &&
                                                <>
                                                    {selectedItem?.product_variants[0].bill_num_times===1 && `This subscription will be billed only on purchase and will not recur.` }
                                                    {selectedItem?.product_variants[0].bill_num_times===null && "This subscription will be billed every year."}
                                                </>
                                            }
                                        </>
                                    }
                                    {selectedItem?.product_type_id === 6 && //bundle
                                        <>
                                            {selectedItem?.bundled_products?.length > 0 &&
                                                `This bundles includes ${selectedItem.bundled_products[0]?.quantity} "${selectedItem.bundled_products[0]?.name}" token${selectedItem.bundled_products[0]?.quantity > 1 ? "s" : ""}.`
                                            }
                                        </>
                                    }
                                </p>
                                <Modal.Footer className="px-0">
                                    <Row>
                                        <Col className="p-0 col-auto">
                                            <Button variant="light" onClick={itemDetailsModalCloseHandler}>Back</Button>
                                        </Col>
                                        <Col className="p-0 col-auto">
                                            <Button 
                                                className="m-0"
                                                data-cy="details-add-to-cart"
                                                onClick={() => {
                                                    addItemHandler(selectedItem, selectedItem.temp_pos_type);
                                                    itemDetailsModalCloseHandler();
                                                }}>Add to Cart
                                            </Button>
                                        </Col>
                                    </Row>
                                </Modal.Footer>
                            </Modal.Body>
                        </Modal>

                        <VariantPicker 
                            activeProduct={activeProduct} 
                            productType={props.type}
                            title="Variants" 
                            show={variantModalShow} 
                            hide={variantModalCloseHandler} 
                            click={props.patron_layout ? examineItemHandler : addItemHandler}
                            patron_layout={props.patron_layout}
                        />
                        <AddonPicker 
                            register_id={props.register_id}
                            activeProduct={activeProduct} 
                            productType={props.type}
                            items={productAddOns}
                            title="Add Ons" 
                            show={addonModalShow} 
                            variantShow={variantModalShow}
                            hide={addonModalCloseHandler} 
                            click={addItemHandler}  
                        />
                        <EventPicker 
                            register_id={props.register_id}
                            activeProduct={activeProduct} 
                            productType={props.type}
                            title="Event Registration" 
                            show={eventModalShow} 
                            hide={eventModalCloseHandler} 
                            click={props.patron_layout ? examineItemHandler : addItemHandler}
                            patron_layout={props.patron_layout}
                        />
                        <RecipientPicker 
                            register_id={props.register_id}
                            activeProduct={activeProduct} 
                            productType={props.type}
                            title="Gift Card Recipient" 
                            show={giftCardModalShow} 
                            hide={giftCardModalCloseHandler} 
                            click={props.patron_layout ? examineItemHandler : addItemHandler}
                            patron_layout={props.patron_layout}
                            type={props.type_id}
                        />
                        <PricePicker
                            title="Product Price" 
                            show={priceOverrideModalShow}
                            hide={priceOverrideModalCloseHandler}
                            activeProduct={activeProduct}
                            click={props.patron_layout ? examineItemHandler : addItemHandler}
                            patron_layout={props.patron_layout}
                        />
                    </div>
                </section>
            );
        }
    } else return null;
}